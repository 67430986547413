export const menuItems = [
    {
        name: "",
        label: "home"
    },
    {
        name: "terms-of-use",
        label: "terms-of-use"
    },
    {
        name: "mentions",
        label: "mentions"
    }
    /*
    {
        name: "contact",
        label: "contact"
    },
    {
        name: "legals",
        label: "legals",
        items: [
            {name: "legals/license-agreement", label: "license"},
            {name: "legals/privacy-policy", label: "privacy"},
            {name: "legals/mentions", label: "mentions"}
        ]
    }
    */
];
