import React from "react";
import styled from "styled-components";
import {Title} from "../Core";

const TitleSpanColor = styled.span`
  background: -webkit-linear-gradient(145deg, #72f, #c1b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const TextLogo = ({variant = "card", white, height, className = "", ...rest}) => {
    return (
        <Title variant={variant} className={`${className}`} {...rest}>
            flash
            <TitleSpanColor>flash</TitleSpanColor>
        </Title>
    );
};

export default TextLogo;
