import React from 'react'
import styled from "styled-components";

const Instagram = styled.a`
  outline: 0;
  margin: 0;
  padding: 10px;
  & a {
    width: 30px;
    display: block;
  }
  &:active {
    outline:0 !important;
  }
  &:focus {
    outline:0 !important;
  }
`;

const InstagramButton = () => {
    const url = "https://www.instagram.com/flashflash.app/?hl=fr"

    return (
        <Instagram
            href={url}
            target="_blank"
            rel="noopener noreferrer"
        >
            <i className="fab fa-instagram fa-2x"/>
        </Instagram>
    )
}

export default InstagramButton
