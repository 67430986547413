import React from 'react'
import styled from "styled-components";
import {I18nextContext, Link, useI18next} from 'gatsby-plugin-react-i18next';
import frFlag from '../../assets/image/svg/fr.svg'
import enFlag from '../../assets/image/svg/en.svg'

const ChangeLanguage = styled.ul`
  outline: 0;
  margin: 0;
  list-style: none;
  padding: 10px;
  & a {
    width: 30px;
    display: block;
  }
  &:active {
    outline:0 !important;
  }
  &:focus {
    outline:0 !important;
  }
`;

const ChangeLanguageButton = () => {

    const {languages, originalPath, t} = useI18next('common');

    const context = React.useContext(I18nextContext);

    return (
        <ChangeLanguage>
            {languages.filter(lang => lang !== context.language).map((lng) => (
                <li key={lng}>
                    <Link to={originalPath} language={lng} alt={t('change-language')}>
                        <img src={lng === 'en' ? enFlag : frFlag} alt={t('change-locale')}/>
                    </Link>
                </li>
            ))}
        </ChangeLanguage>
    )
}

export default ChangeLanguageButton
