import React from "react";
import {Link} from "gatsby";

import imgL1Logo from "../../assets/image/svg/logo.svg";
import imgL1LogoWhite from "../../assets/image/svg/logo-white.svg";
import styled from "styled-components";
import TextLogo from "../TextLogo/TextLogo";

const LogoImg = styled.div`
  & img {
    width: 110px;
  }
`;

const Logo = ({text, white, height, className = "", ...rest}) => {
    return (
        <Link to="/" className={`${className}`} {...rest}>
            <LogoImg>
                {white ? (
                    <img src={imgL1LogoWhite} alt=""/>
                ) : (
                    text ? <img src={imgL1Logo} alt=""/> : <TextLogo variant="card"/>
                )}
            </LogoImg>
        </Link>
    );
};

export default Logo;
