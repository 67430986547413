import React from "react";
import styled from "styled-components";
import { device } from "../../utils";
import {color, shadow, space, typography} from "styled-system";

const Paragraph = styled.p`
  margin-bottom: 0;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 38px;
  ${color};
  ${space};
  ${typography};
  ${shadow};
`;

const ParagraphSmall = styled(Paragraph)`
  font-size: 16px;
  letter-spacing: -0.5px;
  line-height: 28px;
  ${color};
  ${space};
  ${typography};
  ${shadow};
`;

const ParagraphLarge= styled(Paragraph)`
  font-size: 22px;
  @media ${device.lg} {
    font-size: 26px;
  }
  font-family: 'GT Walsheim Pro Regular';
  line-height: 1.5;
  letter-spacing: -.014em;
  ${color};
  ${space};
  ${typography};
  ${shadow};
`;

const Text = ({variant, ...props}) => {
    let TextRender;

    switch (variant) {
        case "small":
            TextRender = ParagraphSmall;
            break;
        case "large":
            TextRender = ParagraphLarge;
            break;
        default:
            TextRender = Paragraph;
    }

    return <TextRender color="text" {...props} />;
};

export default Text;
