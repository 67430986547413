import React from "react";
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Box} from "../Core";
import Logo from "../Logo";
import {useTranslation} from "gatsby-plugin-react-i18next";

const CopyRightArea = styled.div`
  border-top: ${({dark, theme}) =>
    dark ? `1px solid #2f2f31 ` : `1px solid ${theme.colors.border}`};

  padding: 15px 0;
  p {
    color: ${({dark, theme}) =>
    dark ? theme.colors.lightShade : theme.colors.darkShade};
    font-size: 13px;
    font-weight: 300;
    letter-spacing: -0.41px;
    line-height: 38px;
    margin-bottom: 0;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-flex;
    a {
      color: ${({dark, theme}) =>
    dark ? theme.colors.light : theme.colors.dark} !important;
      font-size: 16px;
      transition: 0.4s;
      padding: 0 3px;
      margin: 0 2.5px;
      &:visited {
        text-decoration: none;
      }
      &:hover {
        text-decoration: none;
        color: ${({theme, color}) => theme.colors.secondary} !important;
      }
    }
  }
`;

const FooterWrapper = styled.footer`
    position: absolute;
    bottom: 0;
    width: 100%;
`;

const Footer = ({isDark = true}) => {

    const {t} = useTranslation('common')

    return (
        <FooterWrapper>
            {/* <!-- Footer section --> */}
            <Box bg={isDark ? "dark" : "light"}>
                <Container>
                    <CopyRightArea dark={isDark ? 1 : 0}>
                        <Row className="align-items-center">
                            <Col sm="6" className="text-sm-left text-center mb-2 mb-sm-0">
                                <Logo white={isDark}/>
                            </Col>
                            <Col sm="6" className="text-sm-right text-center">
                                <p>{t('copyright')}</p>
                            </Col>
                        </Row>
                    </CopyRightArea>
                </Container>
            </Box>
        </FooterWrapper>
    );
};

export default Footer;
